.card {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.play {
  max-width: 200px !important;
}

p {
  margin: 20px;
  font-family: "Arial", "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.5;
}

hr {
  margin: 0 20px;
}

#container {
  padding: 0px 0px 30px 0px;
  height: 100%;
  width: 100%;
}

.width {
  max-width: 100%;
}

h1 {
  color: #464646;
  margin: 0px 0px 0px 20px;
  padding: 20px 0px 20px 0px;
  vertical-align: middle;
  font-size: 2em;
  font-weight: bold;
  font-family: "Georgia", Times, Times New Roman, serif;
}

#content {
  height: auto;
  top: 20px;
}

.pink {
  color: #d463bc;
}

.btn-primary {
  background-color: #d463bc !important;
  border-color: #d463bc !important;
}

.navbar {
  border-bottom: 2px solid #d463bc;
  font-family: "Georgia", Times, Times New Roman, serif;
  background-color: white !important;
  flex-wrap: nowrap;
}

.navbar-brand {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #464646;
  font-weight: bold;
  text-align: center;
  color: white !important;
  margin-left: 10%;
  white-space: pre-line;
  font-size: 1.5rem !important;
}

.bg-light {
  background-color: white;
}

.nav-link {
  padding: 0 1rem;
}

.navbar__link--active {
  font-weight: bold;
}

.navbar-collapse,
.navbar-toggler {
  margin-right: 10%;
}

footer {
  background-color: #666666;
  border-top: 5px solid #d463bc;
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: center;
  position: fixed;
  bottom: -5px;
  width: 100%;
}

.contact {
  color: #212529;
  line-height: 1.5;
}

a.nav-link,
a.nav-link:hover,
a.nav-link:visited, 
.text-shadow:hover {
  color: #777777;
  font-weight: bold;
  text-decoration: none;
}

.text-shadow:hover {
  text-shadow: 0.5px 0.5px #d463bc;
}

h5 {
  color: white;
  background-color: #464646;
  text-align: center;
  width: 100%;
  font-family: "Arial", "Helvetica Neue", Helvetica, sans-serif;
  font-size: larger;
  white-space: nowrap;
}

.font-awesome {
  color: #ffffff;
}

.card-img-top {
  height: 45vh;
  width: auto !important;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  align-self: center;
}

.card-body {
  color: #777777;
  margin: 0;
  padding: 3% 0% 0% 0%;
  width: 100%;
  object-fit: cover;
}

.grey-color {
  color: #777777;
}

.card-title {
  font-size: 0.9rem;
  color: #777777;
}

.card-text {
  color: #777777;
  font-size: 0.7rem;
}

.card {
  border: none !important;
  color: #777777 !important;
}

.clear {
  clear: both;
}

.animated-card {
  height: 250px;
  max-width: 100%;
  background: grey;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 5px solid white;
}

.animated-card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .right-border {
    border-right: 0px solid #cccccc;
  }
}
